<!--时间条组件-->
<template>
  <div id="time">
    <div class="container">
      <div class="origin">
        <div class="vertical"></div>
        <span>00:00</span>
      </div>
      <div class="midpoint">
        <div class="vertical"></div>
        <span>12:00</span>
      </div>
      <div class="destination">
        <div class="vertical"></div>
        <span>24:00</span>
      </div>
      <a-tooltip v-for="(itme, index) in show" :key="index">
        <template slot="title">
          <span>{{ `${itme.begin}~${itme.end}` }}</span>
        </template>
        <div class="showTime" :style="itme.style"></div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      show: [],
    };
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        let reveal = [];
        //将时间值列表转换为时间区间对
        if(newVal&&newVal.timeValueList){
          // console.log("showTime watch data newVal",newVal);
          let i=0;
          let timeIntervals=[];
          //先进行排序
          let timeValueLists=JSON.stringify(this.data.timeValueList);
          let temp= JSON.parse(timeValueLists);
          let timeValueList=temp.sort((p1,p2)=>{
            return p1.time-p2.time; 
            });
          while(i<timeValueList.length){
            if(timeValueList[i].binaryValue==0){
              i++;
              continue;
            }
            let begin = (parseInt(timeValueList[i].time/60)+"").padStart(2,'0')+":"+(timeValueList[i].time%60+"").padStart(2,'0');//取开始点
            let end = "";
            //从下一时间值查找结束点
            for(let j=i+1;j<timeValueList.length;j++){
              if(timeValueList[j].binaryValue==0){
                end = (parseInt(timeValueList[j].time/60)+"").padStart(2,'0')+":"+(timeValueList[j].time%60+"").padStart(2,'0');//取结束点
                i=j+1;
                break;
              }
            }
            if(end==""){
              let timeInterval={begin:begin,end:"24:00",model:0};
              timeIntervals.push(timeInterval);
              break;
            }
            else{
              let timeInterval={begin:begin,end:end,model:0};
              timeIntervals.push(timeInterval);
            }
          }        

          console.log("showTime watch data timeIntervals",timeIntervals);

          for (let k in timeIntervals) {
              let begin = timeIntervals[k].begin;
              let end = timeIntervals[k].end;
              let state = timeIntervals[k].model;
              let style = this.renderingCalculation(timeIntervals[k]);
              reveal.push({ begin, end, state, style });            
          }
        }
        this.show = reveal;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 渲染计算方法
    renderingCalculation(value) {
      let second = 86400;//24小时
      let data = {};
      let begin = value.begin;
      if (begin !== "" && begin !== null && begin !== "Invalid date") {
        begin =
          parseInt(value.begin.split(":")[0]) * 60 * 60 +
          parseInt(value.begin.split(":")[1]) * 60;
      }
      let end = value.end;
      if (end !== "" && end !== null && end !== "Invalid date") {
        end =
          parseInt(value.end.split(":")[0]) * 60 * 60 +
          parseInt(value.end.split(":")[1]) * 60;
      }
      data.width = `${((end - begin) / second) * 100}%`;
      data.left = `${(begin / second) * 100}%`;
      if (value.model == 0) {
        data.background = "#0FCE0F";
      } else if (value.model == 1) {
        data.background = "#f8b62d";
      } else if (value.model == 2) {
        data.background = "#eeee00";
      } else if (value.model == 3) {
        data.background = "#7682ce";
      } else if (value.model == 4) {
        data.background = "#00a0e9";
      }
      return data;
    },
  },
};
</script>

<style scoped>
#time {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.container {
  height: 10px;
  width: 100%;
  background: lightgrey;
  position: relative;
  border-radius: 2px 2px 0px 0px;
}
.showTime {
  position: absolute;
  height: 10px;
  transition: 200ms;
}
.showTime:hover {
  transform: scaleY(1.4);
}
.origin {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10px;
  left: -18px;
  font-size: 16px;
}
.midpoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10px;
  left: calc(50% - 20px);
  font-size: 16px;
}
.destination {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10px;
  left: calc(100% - 20px);
  font-size: 16px;
}
.vertical {
  width: 2px;
  height: 10px;
  background: #c7caca;
}
</style>