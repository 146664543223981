<!--设备模块--设备时间表--时间表变更弹出框--->
<template>
  <div id="dialog" ref="dialog">
    <a-modal
      :title="$t('doortimetable.b1')"
      :getContainer="() => this.$refs.dialog"
      :width="700"
      centered
      :visible="visible"
      :destroyOnClose="true"
      @cancel="handleCancel"
      v-drag
    >
      <template slot="footer">
        <a-button
          key="submit"
          :disabled="cancel_disabled"
          @click="handleCancel"
        >
          {{ $t("doortimetable.a23") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="handleOk"
          :disabled="confirm_disabled"
        >
          {{ $t("doortimetable.a22") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('doortimetable.a1')">
        <div class="dialog-name">
          <span>{{ `${$t("doortimetable.b2")}${timeType}` }}</span>
          <span>{{ `${$t("doortimetable.b3")}${name}` }}</span>
          <span>{{ `${$t("doortimetable.b4")}${week}` }}</span>
        </div>
        <!-- 添加图标 -->         
        <div class="dialog-time">   
          <div class="mg-add">            
            <a-icon type="plus-circle" style="vertical-align:10px;" @click="addClick" :title="addTitle"/>
          </div>                  
          <my-timeValue :data="timeValue" v-for="(timeValue,index) in data.timeValueList" @delete="deleteClick" :key="index" :index="index"></my-timeValue>
        </div>
        <div class="dialog-canvas">
          <my-showtime :data="data" v-if="visible"></my-showtime>
        </div>
        <div class="dialog-button">
          <a-button type="primary" style="width: 120px" @click="Reset">
            {{ $t("doortimetable.b11") }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import showTime from "./showTime";
import { updateSchedule } from "../../../api/device";
import timeValue from "./timeValue";

export default {
  components: {
    "my-showtime": showTime,
    "my-timeValue": timeValue,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    name: {
      type: String,
      default: "",
    },
    scheType: {
      type: String,
      default: "",
    },
    week: {
      type: String,
    },
    scheId: {
      type: String,
      default: "",
    },
    //added by li_yj on 2021-10-22 设备时间表设置需设置日历
    calendarId:{
      type: Number,
    }
  },
  data() {
    return {
      addTitle:"添加项",
      confirm_disabled: false,
      cancel_disabled: false,
      loading: false,
      hour:['--','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
      minute:['--','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
      openData: {
        schedule1: {
          begin: ['--','--'],
          end: ['--','--'],
          model:0
        },
        schedule2: {
          begin: ['--','--'],
          end: ['--','--'],
          model:0
        },
        schedule3: {
          begin: ['--','--'],
          end: ['--','--'],
          model:0
        },
        schedule4: {
          begin: ['--','--'],
          end: ['--','--'],
          model:0
        },
        schedule5: {
          begin: ['--','--'],
          end: ['--','--'],
          model:0
        },
      },
    };
  },
  watch: {
    
  },
  computed: {
    timeType: function () {
      switch (this.scheType) {
        case "1":
          return this.$t("doortimetable.b12");
          break;
        case "2":
          return this.$t("doortimetable.b13");
          break;
        case "3":
          return this.$t("doortimetable.b14");
          break;
        case "4":
          return this.$t("doortimetable.b15");
          break;
        default:
          return "";
          break;
      }
    },
    day: function () {
      switch (this.week) {
        case "星期一":
          return 1;
          break;
        case "星期二":
          return 2;
          break;
        case "星期三":
          return 3;
          break;
        case "星期四":
          return 4;
          break;
        case "星期五":
          return 5;
          break;
        case "星期六":
          return 6;
          break;
        case "星期日":
          return 7;
          break;
        case "假日":
          return 8;
          break;
        case "特日1":
          return 9;
          break;
        case "特日2":
          return 10;
          break;
        case "特日3":
          return 11;
          break;
        case "特日4":
          return 12;
          break;
        default:
          return "";
          break;
      }
    },
  },
  methods: {
    //添加新项
    addClick(){     
      if(this.data&&this.data.timeValueList){
        if(this.data.timeValueList.length>=12){
          this.$error({
            title: this.$t("doortimetable.b20"),
            centered: true,
            onOk: () => {},
          });
          return;
        }     
        let timeValue = {                   
          time:null,
          binaryValue:null,             
        };
        this.data.timeValueList.push(timeValue);
      }      
    },
    //删除项
    deleteClick(timeValue,index){
      // console.log("deleteClick timeValue",timeValue);
      console.log("deleteClick index",index);
      this.data.timeValueList.splice(index,1);
      // if(timeValue!=null)
      // {
      //   for(let i=0;i<this.data.timeValueList.length;i++){
      //     if(this.data.timeValueList[i].time==timeValue.time)
      //       this.data.timeValueList.splice(i,1);
      //   }
      // }
    },
    // 重置时间数据
    Reset() {
      if(this.data&&this.data.timeValueList){
        this.data.timeValueList=[];
      }
    },
    // 对话框的确定按钮
    handleOk() {
      let affirm = true; // 执行报错还是接口请求的判断值 
      if(this.data==null||this.data.timeValueList==null)
        return;
      let arr = this.data.timeValueList; // 大数组      
      // 以下方法判断每个时间段是否存在交叉（重复）的情况，是affirm = false
      for (let i = 0; i < arr.length; i++) {
        let a = arr[i][0];
        for (let j = 0; j < arr.length; j++) {
          if (i !== j) {
            if (a > arr[j][0] && a < arr[j][1]) {
              affirm = false;
            }
          }
        }
      }
      // 以下方法判断affirm = false还是true，false报错，true执行接口请求
      if (affirm) {
        this.$confirm({
          title: this.$t("doortimetable.b16"),
          centered: true,
          onOk: () => {
            this.loading = true;
            this.confirm_disabled = true;
            this.cancel_disabled = true;
            let scheId = this.scheId;
            let scheType = this.scheType;
            let day = this.day;     
            let calendarId=this.calendarId;       
            let data = {
              scheId,
              scheType,
              day,
              calendarId,
            };
            console.log("updateSchedule par1:", data);
            console.log("updateSchedule par2:", arr);
            updateSchedule(arr, data)
              .then((res) => {
                // console.log(res);
                this.loading = false;
                this.confirm_disabled = false;
                this.cancel_disabled = false;
                if (res.errorCode == "00") {
                  this.$parent.closeDialog();
                  this.$parent.getData();
                  this.$message.success(res.msg);
                } else if (res.errorCode == "02") {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
                this.confirm_disabled = false;
                this.cancel_disabled = false;
              });
          },
        })
      } else {
        this.$error({
          title: this.$t("doortimetable.b17"),
          centered: true,
          onOk: () => {},
        });
      }
    },
    // 对话框的取消按钮
    handleCancel() {
      this.$parent.closeDialog();
    },
  },
  
};
</script>

<style>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.dialog-name span {
  margin-right: 30px;
}
.mg-add{
  width: 5%;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin: auto 3px;
}
.dialog-time {
  border-top: 1px solid #cccdcd;
  border-bottom: 1px solid #cccdcd;
  padding: 10px 0;
  margin-top: 10px;
}
.dialog-time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dialog-canvas {
  width: 100%;
  height: 80px;
  padding: 10px 20px;
  border-bottom: 1px solid #cccdcd;
}
.dialog-button {
  padding-top: 15px;
}
</style>