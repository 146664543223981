<!--时间设置组件-->
<template>
  <div id="timeValue">
      <a-select class="select" v-model="form.hour" :getPopupContainer="(trigger) => trigger.parentElement" @change="timeValueChange">
        <a-select-option
          v-for="item in hour"
          :key="item"
        >
          {{ item }}
        </a-select-option>
      </a-select>
      <span>{{ $t("doortimetable.b18") }}</span>
      <a-select class="select" v-model="form.minute" :getPopupContainer="(trigger) => trigger.parentElement" @change="timeValueChange">
        <a-select-option
          v-for="item in minute"
          :key="item"
          :disabled="form.hour=='24' && item !== '00'"
        >
          {{ item }}
        </a-select-option>
      </a-select>
      <span>{{ $t("doortimetable.b19") }}</span>
      <a-select class="select" v-model="form.status" :getPopupContainer="(trigger) => trigger.parentElement" @change="timeValueChange">
        <a-select-option
          v-for="item in status"
          :key="item"
        >
          {{ item }}
        </a-select-option>
      </a-select>
      <div class="mg-delete">            
      <a-icon type="delete"  @click="deleteClick"/>
    </div> 
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    index:{
      type: Number,
    }
  },
  data() {
    return {
      hour:['--','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
      minute:['--','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
      status:['--','OFF','ON'],
      form: {hour:"--",minute:"--",status:"--"},
    };
  },
  computed:{
    pluginsDataChange () {      
      if(this.data){
        if(this.data.time!=null){
          this.form.hour = (parseInt(this.data.time/60)+'').padStart(2,'0');
          this.form.minute=(this.data.time%60+'').padStart(2,'0');
        }
        else{
          this.form.hour = "--";
          this.form.minute="--";
        }
        if(this.data.binaryValue!=null){
          this.form.status = this.data.binaryValue==0?"OFF":"ON";
        }
        else{
          this.form.status="--";
        }
        console.log("timeValue watch form:",this.form);
      }
      return JSON.stringify(this.data);
    }
  },
  watch: {
    pluginsDataChange: {
      handler(newVal, oldVal) {
        if(newVal){
          console.log("timeValue watch newVal:",newVal);          
        }        
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    timeValueChange(){
      if(this.form.hour!="--"&&this.form.minute!="--"){
        this.data.time = 60*parseInt(this.form.hour)+parseInt(this.form.minute);
      }
      else
        this.data.time = null;
      if(this.form.status!="--"){
        if(this.form.status == "ON"){
          this.data.binaryValue = 1;
        }else{
          this.data.binaryValue = 0;
        }
      }
      else
        this.data.binaryValue=null;
    },
    deleteClick(){
      this.$emit("delete",this.data,this.index);
    },
  },
};
</script>

<style scoped>
#timeValue {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}
.select {
  width: 70px;
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.mg-delete{
  width: 5%;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  color: red;
  border:1px solid #FFFFFF;
  margin: auto 3px;
}
</style>